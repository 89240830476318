import Vue from "vue";

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCw8nM3PPpg5pDRZ6QV9VWStGx6pSYhiVE",
  authDomain: "dear-soulmates-limited-13d2b.firebaseapp.com",
  databaseURL: "https://dear-soulmates-limited-13d2b-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "dear-soulmates-limited-13d2b",
  storageBucket: "dear-soulmates-limited-13d2b.appspot.com",
  messagingSenderId: "915124343445",
  appId: "1:915124343445:web:fa27185f95c65f9d6a419c",
  measurementId: "G-1SR6HDSDDN"
};





Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "DEAR SOULMATES LIMITED"; //公司名稱
      },
      get regFormDomain() {
        return "https://www.dearsoulmates.xyz/"; 
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor        = "#e8f2fa"

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor      = "#000"

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export {
    firebaseConfig,
    panelColor,
    sideBarColor
}
